import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container, ButtonWrapper, Background, Content, Header } from "./style"
import Slider from "react-slick"
import { ArticlesSliderElement } from "./ArticlesSliderElement"
import { Button } from "../../Button"
import nextArrowImg from "../../../images/slider-arrow-next.svg"
import prevArrowImg from "../../../images/slider-arrow-prev.svg"
import { StaticImage } from "gatsby-plugin-image"

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <img {...props} src={nextArrowImg} alt="sth" />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <img {...props} src={prevArrowImg} alt="sth" />
)

export const ArticlesSlider = ({ articles }) => {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <Container>
      <Background>
        <StaticImage
          src="../../../images/about-us-background.png"
          alt="lines"
          placeholder="blurred"
          objectFit="fill"
          style={{
            width: "100%",
          }}
        />
      </Background>
      <Content>
        <Header>Our Recent Articles</Header>
        <Slider style={{ overflow: "visible" }} {...settings}>
          {articles.map(article => (
            <ArticlesSliderElement key={article.id} article={article} />
          ))}
        </Slider>
        <ButtonWrapper>
          <Button to="/blog" text="See all articles" />
        </ButtonWrapper>
      </Content>
    </Container>
  )
}
