import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  margin: 60px auto;
  max-width: 1200px;
  width: 80vw;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 890px) {
    display: none;
  }
`

export const Background = styled.div`
  width: 100%;
  position: absolute;
  top: -50px;
  right: 0;
  z-index: -1;
`

export const Header = styled.h2`
  @media (max-width: 890px) {
    text-align: center;
    width: 60%;
    margin: 0 auto;
  }
`
