import React from "react"
import {
  Container,
  Header,
  Horizontal,
  Section,
  Top,
  Title,
  Description,
  Star,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"
import star from "../../../../images/star-full.svg"

export const WhatWeDo = () => {
  return (
    <Container id="technology">
      <Star src={star} alt="star" />
      <Header>What we do</Header>
      <Horizontal>
        <Section>
          <Top>
            <StaticImage
              src="../../../../images/what-we-do-1.png"
              alt="light bulb image"
              placeholder="blurred"
              width={72}
              quality="100"
              style={{ margin: "0 15px" }}
            />
            <Title>IoT solutions</Title>
          </Top>
          <Description>
            The IoT is the internet of things. It's a technology that connects
            the physical world to the internet. We use this technology and
            others to create solutions that change the world around us and make
            life better for everyone.
          </Description>
        </Section>
        <Section>
          <Top>
            <StaticImage
              width={61}
              quality="100"
              src="../../../../images/what-we-do-2.png"
              alt="light bulb image"
              placeholder="blurred"
              style={{ margin: "0 15px" }}
            />
            <Title>MCU & MPU Embedded Software</Title>
          </Top>
          <Description>
            Imagine a device that can detect changes in the environment, execute
            actions based on these changes, and sense when its own power supply
            is running low. Our software development for microcontrollers helps
            you build your product and solutions faster and smarter.
          </Description>
        </Section>
        <Section>
          <Top>
            <StaticImage
              width={90}
              quality="100"
              src="../../../../images/what-we-do-3.png"
              alt="light bulb image"
              placeholder="blurred"
              style={{ margin: "0 15px" }}
            />
            <Title>Consumer electronics</Title>
          </Top>
          <Description>
            If you have a product idea, we can help you bring it to life. We
            specialize in designing and prototyping microcontroller-based
            solutions that meet your needs. We have the knowledge and experience
            to take your project from start to finish. We specialize in rapid
            prototyping, development and manufacturing of consumer electronics.
          </Description>
        </Section>
        <Section>
          <Top>
            <StaticImage
              width={72}
              quality="100"
              src="../../../../images/what-we-do-4.png"
              alt="light bulb image"
              placeholder="blurred"
              style={{ margin: "0 15px" }}
            />
            <Title>Support for teams</Title>
          </Top>
          <Description>
            We offer a wide range of services and technical consultancy to
            client teams, including business process modelling and
            implementation of technology solutions to our clients' needs. We use
            our years of experience in industrial design and engineering to
            bring your idea to life.
          </Description>
        </Section>
      </Horizontal>
    </Container>
  )
}
