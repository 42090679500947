import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-top: 120px;
  @media (max-width: 890px) {
    gap: 30px;
    margin: 100px auto 0;
    padding: 0 8vw;
  }
`

export const Header = styled.h2`
  text-align: center;
  @media (max-width: 890px) {
    text-align: start;
  }
`

export const Tags = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 650px;
  gap: 15px;
  @media (max-width: 890px) {
    gap: 8px;
  }
`

export const Star = styled.img`
  position: absolute;
  top: -65px;
  left: 45px;
  @media (max-width: 890px) {
    top: -73px;
    left: 80%;
    text-align: start;
  }
`
