import React from "react"
import { Container, Header, Tags, Star } from "./style"
import { Tag } from "../../../components/Tag"
import star from "../../../images/star-empty.svg"

export const DevelopmentTechnology = () => {
  return (
    <Container>
      <Header>Development technology</Header>
      <Tags>
        <Star src={star} alt="star" />
        <Tag dark text="Socket.Io" />
        <Tag dark text="Mosca MQTT" />
        <Tag dark text="Node.js" />
        <Tag dark text="MongoDB" />
        <Tag dark text="React.js" />
        <Tag dark text="Socket.Io" />
        <Tag dark text="Android" />
        <Tag dark text="Socket.Io" />
        <Tag dark text="Socket.Io" />
        <Tag dark text="Socket.Io" />
      </Tags>
    </Container>
  )
}
