import styled from "styled-components"

export const Container = styled.div`
  margin: 220px auto 0;
  padding: 0 100px;
  max-width: 1400px;
  @media (max-width: 890px) {
    margin: 100px auto 0;
    padding: 0 10vw;
  }
`

export const Header = styled.h2`
  text-align: center;
  display: none;
  @media (max-width: 890px) {
    display: block;
    width: 50%;
    margin: 0px auto 30px;
  }
`
