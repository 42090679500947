import React from "react"
import { Button } from "../../Button"
import {
  Container,
  Background,
  Wrapper,
  Image,
  Description,
  Header,
  Subtitle,
  Left,
  GradientText,
  ButtonWrapper,
} from "./style"
import { StaticImage } from "gatsby-plugin-image"

export const Top = () => {
  return (
    <Container>
      <Background>
        <StaticImage
          src="../../../images/about-us-top-background.png"
          alt="lines"
          placeholder="blurred"
          objectFit="fill"
          style={{
            width: "100%",
          }}
        />
      </Background>
      <Wrapper>
        <Header>
          Embedded <GradientText>Software</GradientText> Development
        </Header>
        <Description>
          <Left>
            <Subtitle>
              Transform your business into the company of the future
            </Subtitle>
            <ButtonWrapper>
              <Button to="#contact-us" text="Contact" />
            </ButtonWrapper>
          </Left>
          <Image>
            <StaticImage
              src="../../../images/about-us-top-image.png"
              alt="network"
              placeholder="blurred"
              quality="100"
            />
          </Image>
        </Description>
      </Wrapper>
    </Container>
  )
}
