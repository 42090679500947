import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
`

export const Header = styled.h2`
  text-align: center;
  @media (max-width: 890px) {
    text-align: start;
  }
`

export const Horizontal = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 75px;
  max-width: 1300px;
  @media (max-width: 890px) {
    grid-template-columns: 1fr;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Top = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
`

export const Title = styled.h4`
  vertical-align: middle;
  font-weight: 700;
  margin: 0;
`

export const Description = styled.p``

export const Star = styled.img`
  position: absolute;
  top: -28px;
  right: 30%;
  @media (max-width: 890px) {
    display: none;
  }
`
