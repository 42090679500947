import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { AboutUs } from "../components/Home/AboutUs"
import { DevelopmentTechnology } from "../components/Home/DevelopmentTechnology"
import { ProductsSlider } from "../components/Home/ProductsSlider"
import { ArticlesSlider } from "../components/Home/ArticlesSlider"
import { ContactUs } from "../components/Home/ContactUs"
import { Top } from "../components/Home/Top"
import { Helmet } from "react-helmet";

export default function About({ data }) {
  const articles = data.articles.nodes
  const products = data.products.nodes
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakDevices</title>
        <meta
          name="description"
          content="Oakdevices - transform Your Business Into The Company Of The Future"
        />
      </Helmet>
      <Top />
      <AboutUs />
      <DevelopmentTechnology />
      <ProductsSlider products={products} />
      <ArticlesSlider articles={articles} />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query ArticlesInfo {
    articles: allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        tags
        slug
        category
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
    }
    products: allContentfulProduct(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        tags
        slug
        category
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }
`
