import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  TextContainer,
  Title,
  TitleMobile,
  ButtonWrapper,
} from "./style"
import { Button } from "../../../Button"

import React from "react"

export const About = () => {
  return (
    <Container>
      <TitleMobile>About us</TitleMobile>
      <StaticImage
        src="../../../../images/about-us-person.png"
        alt="person with laptop image"
        placeholder="blurred"
        objectFit="contain"
        quality="100"
        imgStyle={{
          filter: "drop-shadow(0px 40px 50px rgba(246, 130, 177, 0.3))",
        }}
        style={{
          justifySelf: "start",
          overflow: "visible",
        }}
      />
      <TextContainer>
        <Title>About us</Title>
        <p>
          Geared for entrepreneurs who are seeking to prototype, research and
          develop their product or design. We develop embedded solutions and
          create software for microcontrollers providing full R&D services with
          a strong emphasis on visualization, analysis and design. We have
          expertise in all aspects of electronic design, system integration,
          microcontrollers and QT interfaces. Customers include those who need
          pre-production prototypes. Contact us if you are looking for a strong
          business partner to handle the complete electronics research and
          development process.
        </p>
        <ButtonWrapper>
          <Button to="/#contact-us" text="Contact" />
        </ButtonWrapper>
      </TextContainer>
    </Container>
  )
}
