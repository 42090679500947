import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  margin: 70px 0 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 10vw 0;
  max-width: 1400px;
  flex-direction: column;
  gap: 150px;
  @media (max-width: 890px) {
    gap: 70px;
    margin: 10px auto 0;
    padding: 0 8vw;
  }
`

export const Background = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  @media (max-width: 890px) {
    top: 430px;
    width: 150%;
    left: -50px;
    transform: rotate(-10deg);
  }
`
