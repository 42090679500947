import styled from "styled-components"

export const Loader = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 890px) {
    width: 100%;
  }
`
