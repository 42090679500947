import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container, Header } from "./style"
import Slider from "react-slick"
import { SectionElement } from "../../SectionElement"
import nextArrowImg from "../../../images/slider-arrow-next.svg"
import prevArrowImg from "../../../images/slider-arrow-prev.svg"

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <img {...props} src={nextArrowImg} alt="sth" />
)

const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <img {...props} height="150px" src={prevArrowImg} alt="sth" />
)

export const ProductsSlider = ({ products }) => {
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }
  return (
    <Container>
      <Header>Explore Our Work</Header>
      <Slider {...settings}>
        {products.map(product => (
          <SectionElement key={product.id} product={product} />
        ))}
      </Slider>
    </Container>
  )
}
