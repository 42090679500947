import React from "react"
import { Loader } from "./style"
import loader from "./loader.svg"

const ContactLoader = () => {
  return (
    <Loader>
      <img src={loader} alt="loader" />
    </Loader>
  )
}

export default ContactLoader
