import styled from "styled-components"
import background from "../../../images/contact-circles.png"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  margin: 100px auto 0;
  max-width: calc(1400px + 8vw);
  padding: 50px 8vw 0;
  @media (max-width: 890px) {
    margin: 10px auto 0;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    padding: 0 8vw;
  }
`

export const Title = styled.h2`
  background: -webkit-linear-gradient(
    0deg,
    #f23fc5 -30%,
    #f67bb2 10%,
    #a994dc 40%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Input = styled.input`
  font-size: 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  padding: 1em 0px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #212529;
    opacity: 0.5;
  }
`

export const TextArea = styled.textarea`
  font-size: 16px;
  width: 100%;
  border: none;
  min-height: 40px;
  border-bottom: 1px solid black;
  padding: 10px 1px;
  resize: vertical;
  font-family: Sans-serif;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #212529;
    opacity: 0.5;
  }
`

export const Label = styled.label`
  font-size: 12px;
  display: block;
`

export const Error = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: #d20808;
  font-size: 0.8em;
  margin-top: 0.7em;
`

export const Left = styled.div`
  display: grid;
  grid-template-rows: 70px 70% 15%;
`

export const Text = styled.p`
  background-image: url(${background});
  align-self: start;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Fahkwang";
  line-height: 72px;
  font-size: 48px;
  font-weight: 500;
  padding: 20px 60px;
  max-width: 650px;
  height: 9em;
  @media (max-width: 890px) {
    padding: 20px 0px;
    font-size: 24px;
    line-height: 40px;
  }
`

export const Form = styled.form`
  display: ${props => (props.isActive ? "flex" : "none")};
  height: 700px;
  flex-direction: column;
  @media (max-width: 890px) {
    width: 100%;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  @media (max-width: 890px) {
    justify-content: center;
  }
`

export const ButtonWrapper = styled.button`
  height: 50px;
  display: inline-block;
  padding: 10px 18px;
  border-radius: 25px;
  font-size: 1.1em;
  border: none;
  background-image: linear-gradient(90deg, #f23fc5, #f67bb2, #a994dc);
  &:hover {
    filter: drop-shadow(0px 2px 6px rgba(246, 123, 178, 0.4));
    border: none;
  }
  &:active {
    border: none;
    background-image: linear-gradient(
      81.84deg,
      #952178 -200.74%,
      #c65286 53.5%,
      #a28cd7 230.36%
    );
  }
  @media (max-width: 890px) {
    align-self: stretch;
  }
`

export const ButtonText = styled.h5`
  margin: 0;
  font-weight: normal;
  color: #fff;
  ${Container}:active & {
    -webkit-text-fill-color: #fff;
  }
`
