import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-rows: 65% 35%;
  border-radius: 25px;
  overflow: hidden;
  height: 320px;
  box-shadow: 0px 8px 20px rgba(122, 153, 237, 0.2);
  margin: 40px 15%;
  @media (max-width: 890px) {
    box-shadow: 0px 8px 12px rgba(122, 153, 237, 0.2);
    height: auto;
    margin: 20px 10px;
  }
`

export const Top = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative;
`

export const Bottom = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 15px 10px;
  background-color: white;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
`

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  @media (max-width: 890px) {
    font-weight: 500;
  }
`
