import styled from "styled-components"

export const MessageContainer = styled.div`
  height: 600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`

export const Message = styled.p`
  font-size: 2.5rem;
  text-align: center;
`

export const SecondMessage = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  background: -webkit-linear-gradient(
    0deg,
    #f23fc5 -30%,
    #f67bb2 10%,
    #a994dc 40%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
