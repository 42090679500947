import React from "react"
import { Container, Top, Bottom, Wrapper, Background, Title } from "./style"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { ReadMore } from "../../../ReadMore"

export const ArticlesSliderElement = ({ article }) => {
  const image = getImage(article.thumbnail)
  return (
    <Container>
      <Top>
        <GatsbyImage image={image} alt={article.slug} placeholder="blurred" />
        <Background>
          <StaticImage
            src="../../../../images/gradient-texture.png"
            alt="gradient"
            placeholder="blurred"
            style={{ height: "100%" }}
          />
        </Background>
      </Top>
      <Bottom>
        <Title>{article.title}</Title>
        <Wrapper>
          <ReadMore desiredPath={"/blog/" + article.slug} />
        </Wrapper>
      </Bottom>
    </Container>
  )
}
