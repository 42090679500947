import { StaticImage } from "gatsby-plugin-image"
import { Container, Background, Content } from "./style"
import { About } from "./About"
import { WhatWeDo } from "./WhatWeDo"
import React from "react"

export const AboutUs = () => {
  return (
    <Container>
      <Background>
        <StaticImage
          src="../../../images/about-us-background.png"
          alt="lines"
          placeholder="blurred"
          objectFit="fill"
          style={{
            width: "100%",
          }}
        />
      </Background>
      <Content>
        <About />
        <WhatWeDo />
      </Content>
    </Container>
  )
}
