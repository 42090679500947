import styled from "styled-components"

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`
export const Wrapper = styled.div`
  width: 100%;
  margin: 50px 10vw 0;
  max-width: 1400px;
  @media (max-width: 890px) {
    margin: 10px auto 0;
    padding: 0 8vw;
  }
`
export const Background = styled.div`
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 70%;
  z-index: -1;
  @media (max-width: 890px) {
    bottom: -100px;
    left: 0;
    width: 100%;
  }
`
export const Image = styled.div`
  padding-top: 25px;
  filter: drop-shadow(0px 20px 20px rgba(244, 127, 172, 0.3));
`

export const Description = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 890px) {
    display: block;
  }
`
export const Left = styled.div``

export const Header = styled.h1`
  margin-bottom: 20px;
`

export const Subtitle = styled.p`
  margin-bottom: 40px;
  font-size: 24px;
  text-transform: capitalize;
  @media (max-width: 890px) {
    font-size: 16px;
  }
`

export const GradientText = styled.span`
  letter-spacing: 0.05em;
  font-size: 96px;
  font-family: "Fahkwang", sans-serif;
  font-weight: 500;
  background: -webkit-linear-gradient(
    0deg,
    #d738bd 0%,
    #f283ad 27.78%,
    #f8b2ac 45.69%,
    #f97db0 75.49%,
    #af9add 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 890px) {
    font-size: 36px;
    display: block;
    text-align: end;
  }
`

export const ButtonWrapper = styled.div`
  @media (max-width: 890px) {
    display: none;
  }
`
