import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  @media (max-width: 890px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 890px) {
    margin-top: 75px;
  }
`

export const Title = styled.h2`
  margin-bottom: 40px;
  @media (max-width: 890px) {
    display: none;
  }
`

export const TitleMobile = styled.h2`
  display: none;
  margin-bottom: 6px;
  align-self: start;
  @media (max-width: 890px) {
    display: block;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 2em;
`
